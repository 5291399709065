.App {
    width: 100%;
    height: 100%;
    padding: 0px;
    max-width: 720px;
    display: inline;
    margin: auto;
    font-size: 14px;
    color: '#2A312F'
}

.bottomNavigation {
    width: '100%';
    max-width: 720px!important;
    margin: auto!important;
}

.root {
    width: '100%';
    max-width: 720px!important;
    margin: auto!important;
}